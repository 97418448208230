import React from "react";
import dragon_one from '../../images/Kung-Fu-Bath-Dragon-1.JPG';
import dragon_two from '../../images/Kung-Fu-Bath-Dragon-2.JPG';
import dragon_three from '../../images/Kung-Fu-Bath-Dragon-3.JPG';
import kids_class from '../../images/Kung-Fu-Bath-kids-class-1.jpg';
import adults_class from '../../images/Bath-Kung-Fu-Academy-Stick-Fighting.jpeg';

//C:\Users\Lscho\Desktop\Desktop Documents\Projects_Backup_While_Hardrive_Is_Failing\kung-fu-bath-rebuild\src\images\
const HomeContent = () => (
    <div className="Content">
        <hr/>
        <p>Welcome to The Bath Kung Fu Academy</p>
        <hr/>
        <span>
            <img src={dragon_one} alt="kung fu bath academy dragon china trip" style={{ width: "30%", height: "auto", border: "4px ridge white" }} />
            <img src={dragon_two} alt="kung fu bath academy dragon two china trip" style={{ width: "30%", height: "auto", border: "4px ridge white" }} />
            <img src={dragon_three} alt="kung fu bath academy dragon three china trip" style={{ width: "30%", height: "auto", border: "4px ridge white" }} />
        </span>
        <p>The Bath Kung Fu Academy is pleased to offer professional step by step instruction for men, women and children of all ages. We guide you from complete beginner to advanced practitioner and pride ourselves on being fun and friendly.</p>
        <p>The Academy is part of the Wei-t-o Kung Fu organization and is recognized by the British Counsel for Chinese martial arts (BCCMA). We are Qualified in First Aid and CRB checked.</p>
        <br/>
        <a href="/schedule" >
            <div className="Bubble">
                <p><b><i>ADULT & JUNIOR CLASSES</i></b></p>
            </div>
        </a>
        <br/>
        <p>Details about the content of our classes and the grading structure can be found on our <a href="/syllabus" >Syllabus</a> page.</p>
        <br/>
        <hr/>
        <p><b>KUNG FU BATH - KIDS ACADEMY - Age 5-11</b><br/>Monday, Wednesday & Thursday Evenings 6:00pm - 6:45pm</p>
        <a href="/schedule" >See our schedule</a>
        <br/>
        <img src={kids_class} alt="kung fu bath academy kids class" style={{width: "30%", height: "auto", border: "4px ridge white" }} />
        <hr/>
        <p><b>KUNG FU BATH - ADULTS ACADEMY - Age 12+</b><br/>Monday, Wednesday & Thursday Evenings 7:00pm - 8:30pm</p>
        <a href="/schedule" >See our schedule</a>
        <br/>
        <img src={adults_class} alt="kung fu bath academy stick fighting adults class" style={{ width: "40%", height: "auto", border: "4px ridge white" }} />
        <hr/>
        <br/>
        <p>For prices and membership structure, please consult our <a href='/membership'>MEMBERSHIP</a> page.</p>
        <br/>
        <hr/>
        <hr/>
    </div>
)


export default HomeContent;