import { useRouteError } from "react-router-dom";
import HomeContent from "./home_content";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <HomeContent/>
  );
}