import React from "react";
import sifu_one from '../../images/Kung-Fu-Bath-Sifu-Jerry-Burgess.JPG';
import sword_fighting from '../../images/Bath-Kung-Fu-Academy-Sword-Fighting.jpeg';

const Membership = () => (
    <div className="Content">
        <hr/>
        <p>Bath Kung Fu Academy - Membership</p>
        <hr/>
        <p><b>Membership Application</b></p>
        <p>Before your Bath Kung Fu Membership can begin:
            <br/>
            Please e-mail info@kungfubath.com <br/> or <br/> Call us on Tel: 0774 8901183 <br/>
            with your name, contact number & preferred class.</p>
        <hr/>
        <p><b>STARTING WITH BATH KUNG FU ACADEMY!</b></p>
        <img src={sifu_one} alt="sifu jerry burgess bath kung fu syllabus" style={{ objectFit: "cover", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <p>First, we encourage our students to attend our <i>Two Trial Beginner Sessions</i> held at any of our venues. This will introduce you to the principle foundations of Kung Fu.</p>
        <p>After, you may then attend your chosen classes the following week to begin your journey from being a beginner to an advanced practitioner. <br/> We always strive to be as flexible as possible with which venue you attend with your chosen training structure.</p>
        <p>The Academy prides itself on being fun, friendly & functional.</p>
        <hr/>
        <p><b>Bath Kung Fu Fee Structure</b></p>
        <img src={sword_fighting} alt="sifu jerry burgess bath kung fu syllabus" style={{ objectFit: "cover", objectPosition: "top", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <div style={{width: "70%", textAlign: "left"}}>
        <p><i>Beginner Sessions : Two Trial Sessions</i></p>
            <ul>
                <li>Juniors: £10</li>
                <li>Adults: £15</li>
            </ul>
        </div>
        <div style={{width: "70%", textAlign: "left"}}>
        <p><i>Option 1 : One Class Weekly</i></p>
            <ul>
                <li>Juniors: £33 per month</li>
                <li>Adults: £44 per month</li>
            </ul>
        </div>
        <div style={{width: "70%", textAlign: "left"}}>
        <p><i>Option 2 : Two Class Weekly</i></p>
            <ul >
                <li>Adults: £57 per month</li>
            </ul>
        </div>
        <div style={{width: "70%", textAlign: "left"}}>
        <p><i>Option 3 : Three Class Weekly</i></p>
            <ul>
                <li>Adults: £65 per month</li>
            </ul>
        <p>Please Note: Our membership structures are priced to include closures such as Bank Holidays or Festive Periods, however we love to see our students attend an alternative session should a holiday cause a session to be missed!</p>
        </div>
        <hr/>
        <p><b>CONTINUOUS MEMBERSHIP AGREEMENT</b></p>
        <p>
            Kung Fu requires dedication, this is why we fix our prices for any active
            subscription. <br/> While you're training and continuously paying for your membership, the price will never increase!
        </p>
        <hr/>
        <hr/>
    </div>
)

export default Membership;