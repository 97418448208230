import React from "react";

const ContactRow = ({icon, text, linkTo, whiteIcon = false}) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            margin: "0 0 0 0",
        }}>
            <div style={{padding: '1rem 0'}}>
                <a target="/" href={linkTo} style={{
                    color: whiteIcon ? 'white' : '#be2e2d', 
                    
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-evenly",
                    margin: "0 0 0 0",
                }}>
                    {icon} {text}
                </a>
            </div>
        </div>
    )
}

export default ContactRow;