import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { getWidth, debounce } from './utils/screen';
import './App.css';
import Header from './components/header';
import ErrorPage from './components/routes/no_route';
import HomeContent from './components/routes/home_content';
import AboutUs from './components/routes/about_us';
import Schedule from './components/routes/schedule';
import Footer from './components/footer';
import History from './components/routes/history';
import Syllabus from './components/routes/syllabus';
import Membership from './components/routes/membership';
import FAQ from './components/routes/faq';
import Contactus from './components/routes/contactus';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeContent/>,
    errorElement: <ErrorPage />
  },
  {
    path: "/home",
    element: <HomeContent/>
  },
  {
    path: "/aboutus",
    element: <AboutUs />
  },
  {
    path: "/schedule",
    element: <Schedule />
  },
  {
    path: "/history",
    element: <History />
  },
  {
    path: "/syllabus",
    element: <Syllabus />
  },
  {
    path: "/membership",
    element: <Membership />
  },
  {
    path: "/faq",
    element: <FAQ />
  },
  {
    path: "/contactus",
    element: <Contactus />
  },
]);

function App() {

  const [useDesktopView, setUseDesktopView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setUseDesktopView(getWidth() >= 768);
    };
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <>
      <div className="App">
      <Header useDesktopView={useDesktopView} />
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "70%"
      }}>
        <RouterProvider router={router} />
      </div>
      <Footer/>
      </div>
    </>
  );
}

export default App;
