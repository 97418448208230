import React from "react";

const FAQ = () => (
    <div className="Content">
        <hr/>
        <p><b>Bath Kung Fu Academy - FAQ</b></p>
        <hr/>
        <p><b>WHAT WILL I LEARN?</b></p>
        <div style={{width: "70%", textAlign: "left"}}>
            <ul>
                <li>Pugilism: Avoiding and controlling punches and kicks and striking with power.</li>
                <li>Grappling: Avoiding joint and muscle locks and using leverage to apply locks.</li>
                <li>Throwing: How to stay balanced to uproot and throw an opponent.</li>
                <li>Vital points: Understanding anatomy and medicine to attack vulnerable points.</li>
                <li>Energy: Cultivating energy for health and vitality, and using it in defense and attack.</li>
                <li>Weapons: Use a wide range of weapons such as: the staff, sword, dagger, spear and walking stick to strike, trap, throw, attack vital points and focus energy.</li>
            </ul>
        </div>
        <hr/>
        <p><b>WHAT ARE THE FIVE ANIMALS?</b></p>
        <div style={{width: "70%", textAlign: "left"}}>
            <ul>
                <li>Panther: Fast, light, precision strikes are combined with agile footwork</li>
                <li>Crane: Powerful movements of arms and upper body escape locks and uproot opponents</li>
                <li>Dragon: A light wiry body focuses internal energy to grip and grapple</li>
                <li>Snake: Soft and yielding, absorbs and deflects strikes, lighting fast counter-strikes</li>
                <li>Tiger: Awesomely powerful strikes and immense strength</li>
            </ul>
        </div>
        <hr/>
        <p><b>DO I HAVE TO BE FIT?</b></p>
        <p>Due to the way the classes are structured you can join no matter what level of fitness you have. If you have specific concerns about the level of activity please feel free to contact the instructor <a href='/contactus'>(Contact Us)</a>. If you have any health concerns regarding medical conditions please consult your GP prior to commencing.</p>
        <hr/>
        <p><b>DO YOU HAVE A GRADING SYSTEM?</b></p>
        <p>Yes, we have a stage by stage grading system. More information on this within <a href='/syllabus'>syllabus.</a></p>
        <hr/>
        <p><b>WHAT SHOULD I WEAR?</b></p>
        <p>All you need to bring are clean tracksuit bottoms, a plain t-shirt and a bottle of water/sports drink. We also sell seasonal uniform.</p>
        <hr/>
        <p><b>HOW MUCH PHYSICAL CONTACT IS THERE?</b></p>
        <p>Martial Arts is a contact art, although we make it as safe as we can. Ensuring appropriate levels of contact for ability are applied i.e. beginners are encouraged by more advanced students not beaten on. We also have strict rules with anger, as no member is allowed to hit another in anger.</p>
        <p>Protective guards are suggested where necessary and are available to buy at discounted rate from the Academy.</p>
        <hr/>
        <p><b>IS HUNG KUEN SUITABLE FOR WOMEN?</b></p>
        <p>The foundations of Hung Kuen are balance, natural movement and good body mechanics. This makes it an ideal art for women.</p>
        <hr/>
        <p><b>IS A PARTICULAR BODY SHAPE BETTER FOR HUNG KUEN?</b></p>
        <p>The whole point of martial arts is that they are for everybody. It doesn’t mater whether you are tall or short, big or small, male or female. This is the art for you.</p>
        <hr/>
        <p><b>DOES THE CLUB BELONG TO A RECOGNIZED ORGANISATION?</b></p>
        <p>The club is part of the Wei-t-o kung fu organisation. It is recognised by the British Counsel for Chinese martial arts (BCCMA).</p>
        <hr/>
        <p><b>HOW LONG DOES IT TAKE TO BECOME EFFECTIVE?</b></p>
        <p>From day one you’ll start building a skill base that will shape into an effective self-defence system. Obviously the longer you travel with your training the higher the level will be achieved bringing all area’s of combat and weapons to a functional standing that will build confidence in your ability.</p>
        <hr/>
        <hr/>

    </div>
)

export default FAQ;