import React from "react";
import logo from '../logo.png';
import logo2 from '../logo2.png';
import logo3 from '../logo_3_proto.png';

const Header = ({useDesktopView}) => (
    <>
    <div style={{ 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%" 
        }}>
        <img src={logo2} alt="logo2" />
        <img src={logo3} alt="logo3" />
        <img src={logo} alt="logo" />
    </div>
    <p style={{fontSize: "0.7rem", color: "yellow"}}>Bath Kung Fu Academy. Fun, friendly & functional</p>
    <div style={useDesktopView ? { 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        } : {display: "flex", flexDirection: "column"}}>
            <a href="/">Home</a>
            <a href="/aboutus">About Us</a>
            <a href="/schedule">Schedule</a>
            <a href="/history">History</a>
    </div>
    <div style={useDesktopView ? { 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        } : {display: "flex", flexDirection: "column"}}>
            <a href="/syllabus">Syllabus</a>
            <a href="/membership">Membership</a>
            <a href="/faq">FAQ</a>
            <a href="/contactus">Contact Us</a>
    </div>
    <br/>
    </>
)

export default Header;