import React, { useState } from "react";
import history_one from '../../images/History_02.jpg';
import history_two from '../../images/Kung_Fu_Bath_Lim-Sam.jpg';
import history_three from '../../images/Kung_Fu_Bath_Jeff_Hasbrouck.jpg';
import history_four from '../../images/Kung_Fu_Bath_Jerry_Burgess.jpg';

const History = () => {
    const [screenWidth] = useState(window.innerWidth);

    return (
        <div className="Content">
            <hr />
            <p>Bath Kung Fu Academy - History</p>
            <hr />
            <p>A brief overview of the history of our tradition.</p>
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div style={{ marginRight: screenWidth > 1020 ? "3rem" : "", }}>
                    <img src={history_one} alt="origin" style={{ width: "auto", minHeight: "15rem", border: "4px ridge white" }} />
                </div>
                <div>
                    <p><b>Leung Kwan "Tit Kiu Sam" (1815 -1888)</b></p>
                    <p>Leg Kwan was born into a wealthy family in the Nam Hai, Kwantung province. His father paid for him to study martial arts under several famous masters.</p>
                    <p>One of his teachers was the influential Kwok Yan Si who taught the "Fuk Fu Kuen", the (old) "Fu Hoc Seung ying Kuen", and a special method of training the arms and stances, known as "Diamond Body Technique".</p>
                    <p>From this training Leung Kwan developed incredibly strong arms and legs that earned him the nickname of "Tit Kiu Sam". Tit Kiu Sam's first student was a school teacher called Lee Chung.From this training Leung Kwan developed incredibly strong arms and legs that earned him the nickname of "Tit Kiu Sam". Tit Kiu Sam's first student was a school teacher called Lee Chung.</p>
                    <p>Tit Kiu Sam's second student was Coi Chan and the third was Ng Hei Kwoon. Tit Kui Sam's other students were Lam Fook Shing, Ngau Chu, Si Yu Lueng, Blackfaced Sing and Six-Fingered Tiem.</p>
                </div>
            </div>
            <hr />
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div>
                    <p><b>Ng Hei Kwoon</b></p>
                    <p>During the 1860's Ng Hei Kwoon came to Canton and became the indoor disciple of Tit Kiu Sam. As well as studying with Tit Ku Sam, Ng Hei Kwoen also followed classes at the Canton dye works near Rainbow bridge. He trained there until the passing of Tit Kiu Sam in 1888. It was after this time that Ng Hei Kwoon devoted himself to Buddhism and started upon the monastic path taking on the name 'Yan Gong Sim Si'. After becoming an ordained monk his first disciple was a 13 year old boy called Hang Yat Siu.</p>
                </div>
            </div>
            <hr />
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div>
                    <p><b>Hang Yat Siu</b></p>
                    <p>Hang Yat Siu's father was a member of the underground movement fighting against the government of his time. After his father's death being still a young boy, Hang Yat Siu returned with his family to Canton in Kwantung his mothers birthplace. At the age of 13 he left his family and followed a nomadic path.</p>
                    <p>Eventually he found his way to a small temple near Canton where he worked in the kitchen in exchange for food and shelter. After a time Hang Yat Siu devoted himself to Buddhism and became a lay monk taking the name 'Lin Hung Sim Si'. He became a student of the Zen master Yan Gong who taught him traditional martial arts.</p>
                    <p>During his travels he met and befriended the father of Lai Ng Sam and after Lai Ng Sam's father's death adopted Lai Ng Sam as his son. As Hang Yat Siu grew older and his eye sight deteriorated his wanderings came to an end and he settled down at a small temple near Changsha, Wunam. During this time he accepted five more students and wrote letters of introduction to other teachers so his students could exchange forms and continue learning southern shoalin boxing.</p>
                </div>
            </div>
            <hr />
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div style={{ marginRight: screenWidth > 1020 ? "3rem" : "", }}>
                    <img src={history_two} alt="Lai Ng Sam" style={{ width: "auto", height: "15rem", border: "4px ridge white" }} />
                </div>
                <div>
                    <p><b>Lai Ng Sam (1927 - 1995)</b></p>
                    <p>Lai Ng Sam was born in Futshan village in Kwantung in 1927. His father was an herbal doctor and Mok Ga kung fu teacher. During his travels Lai Ng Sams's father met Hang Yat Siu in the village of Shen Tong. The two became good friends and made a living selling herbs and giving kung fu demonstrations. When Lai Ng Sam was seven his father passed away and he was adopted by Hang Yat Siu. From 1934 Lai Ng Sam was the adopted son and student of Hang Yat Siu being trained in the arts of herbal medicine and southern Chinese boxing.</p>
                    <p>In 1937 Japan invaded China with the intention of dominating the Asian mainland. It was a turbulent time for the Chinese people, and Hang Yat Sui and Lai Ng Sam were both actively involved in the resistance movement against the Japanese invaders.</p>
                    <p>After the Second World War the true nature of the Maoist regime became obvious and in 1949 Hang Yat Sui instructed each of his students to flee the country. Lai Ng Sam was lucky to have succeeded in escaping mainland China, not many did. He arrived in Hong Kong where he made a new life for himself continuing to teach martial ars just as they were given to him. He taught at the YMCA, the Japanses embassy and in Victoria Park, where he taught on a daily basis. It was in this park, in the 1970's, that Jeff Hasbrouck was introducted to Lai Ng Sam, becoming both a student and a friend. Tragically Lai Ng Sam contracted cancer and during his final days appointed Jeff Hasbrouck as his offical succesor. Sadly Lai Ng Sam passed away in November 1995.</p>
                </div>
            </div>
            <hr />
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div style={{ marginRight: screenWidth > 1020 ? "3rem" : "", }}>
                    <img src={history_three} alt="Jeff Hasbrouck " style={{ width: "auto", height: "15rem", border: "4px ridge white" }} />
                </div>
                <div>
                    <p><b>Jeff Hasbrouck (1947 - Present)</b></p>
                    <p>Born in the USA, Jeff left for Europe in 1968. While in Amsterdam he studied Tai Ji Quan and was drawn to Asia to further his spiritual and martial art interests. In 1977 Jeff and his wife moved to Hong Kong.</p>
                    <p>Jeff was introduced to Yong Sek Yee a second generation teacher of Wu Style Tai Ji, and became a dedicated student. It was during this period of training that a kung fu brother CC Wong suggested that Jeff balanced his martial arts training by also studying southern shaolin Chinese boxing. CC Wong introduced Jeff to Lai Ng Sam and after months of gruelling training was accepted as a student.</p>
                    <p>Jeff was fortunate in being able to dedicated years of his life to studying with both of his teachers without many distractions. Jeff trained with Lai Ng Sam daily morning and evening, for several years. During this time Jeff also became a proficient acupuncturist, Chinese painter and studied Chinese arts and culture.</p>
                    <p>Jeff returned to Europe in the early 80’s and began teaching Southern Chinese boxing and has continued to do so to the present day. Jeff visited his teacher in Hong Kong as often as he could and in 1995 was urgently called back to Hong Kong to be by the side of his sick and dying Sifu. Jeff was apointed by Lai Ng Sam as the offical successor to this lineage of southern Chinese Boxing.</p>
                </div>
            </div>
            <hr />
            <div style={{
                display: "flex",
                flexDirection: screenWidth > 1020 ? "row" : "column",
                alignItems: screenWidth > 1020 ? "flex-start" : "center",

            }}>
                <div style={{ marginRight: screenWidth > 1020 ? "3rem" : "", }}>
                    <img src={history_four} alt="Jerry Burgess" style={{ width: "auto", height: "15rem", border: "4px ridge white" }} />
                </div>
                <div>
                    <p><b>Jerry Burgess (1970 - Present)</b></p>
                    <p>Born in the South West of England Jerry has always had a strong love for sport, growing up Jerry attending gymnastics and later became involved in fitness/weight training winning several awards in Power Lifting and Weight Training related titles. However at the age of 18 his good friend dragged him along too a Kung Fu demonstration, upon seeing the awesome speed, techniques & power of these Kung Fu practitioners he became a dedicated student. As his thirst grew he also spent one to one time with his teacher training in specific traditional hand & weapon systems.</p>
                    <p>Jerry’s vocations have always been health & fitness related and has included lifeguarding, teaching sport abroad (USA & Europe), health & fitness consultation and management. However he strongly advocates that it is a knowledge of martial arts that enables individuals to learn skills & physical ability that can be crossed over into assisting other sports. Whilst assisting James Crossley (Hunter in TV’s Gladiators) with learning gymnastics in 1994, Jerry was interested to hear from James that the most physically adaptable athletes he’d come up against in the series were martial artists & gymnasts.</p>
                    <p>This was later echoed by British Motocross Champion ‘Mark Hucklebridge’ who after studying Kung Fu at ‘The Kung Fu Academy’ professes that the whole Kung Fu process has increased his reaction timing, endurance, speed and balance.</p>
                    <p>Jerry continues to both enjoy studying as ‘Indoor’ student of Sifu Hasbrouck and also passionately instruct all levels from the beginner through to the highly advanced.</p>
                </div>
            </div>
        <hr/>
        <hr/>
        </div>
    )
}

export default History;