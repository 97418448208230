import React from "react";
import grading_one from '../../images/Kung-Fu-Bath-Grading-1.jpg';
import sifu_one from '../../images/Kung-Fu-Bath-Sifu-Jerry-Burgess.JPG';
import sifu_two from '../../images/Kung-Fu-Bath-Sifu-Jerry-Burgess-Sword.JPG';

const Syllabus = () => (
    <div className="Content">
        <hr/>
        <p>Bath Kung Fu Academy - Syllabus</p>
        <hr/>
        <p><b>KUNG FU SYLLABUS</b></p>
        <img src={sifu_one} alt="sifu jerry burgess bath kung fu syllabus" style={{ objectFit: "cover", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <p>The Academy has a syllabus & grade system designed to take you from the very first steps of learning through to the highest levels of Kung Fu.</p>
        <p>The journey starts with understanding the fundamentals of Kung Fu and creates a solid base from which to grow, these include;</p>
        <div style={{width: "70%", textAlign: "left"}}>
        <ul>
            <li>Combat Concepts & skill acquisition</li>
            <li>Kicking (Low and High)</li>
            <li>Punching (Various Animal striking hands)</li>
            <li>Basic locking techniques</li>
            <li>Blocking & deflecting (inclusive of breaking off grabs)</li>
            <li>Knee attacks, Sweeps & Leg traps</li>
            <li>Stances & ‘Walking form’</li>
        </ul>
        </div>
        <p>Once the above knowledge has been achieved, we move into the various systems of Kung Fu & weapons.</p>
        <hr/>
        <p><b>KEY AREAS OF MARTIAL STUDY</b></p>
        <img src={sifu_two} alt="sifu jerry burgess bath kung fu syllabus" style={{ objectFit: "cover", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <div style={{width: "70%", textAlign: "left"}}>
        <ul>
            <li>Forms</li>
            <li>Body conditioning</li>
            <li>Pugilism</li>
            <li>Chin Na (locks and holds)</li>
            <li>Di Mak (vital point striking)</li>
            <li>Grappling and down fighting</li>
            <li>Integrated Chi Gong</li>
            <li>Bridging (physical and physiological)</li>
            <li>Bo Staff, Spears, Swords, Daggers and more!</li>
            <li>Strategy</li>
            <li>Meditation</li>
        </ul>
        </div>
        <hr/>
        <p><b>OUR GRADING SYSTEM</b></p>
        <img src={grading_one} alt="kung fu bath academy grading" style={{ objectFit: "cover", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <p><i>Juniors</i></p>
        <p>
            Junior class members follow a unique grading system. The system is a 'coursework' concept
            which takes the students through checklists of identified skill levels at various aspects 
            of the art, first achieving an <span style={{color: "orange"}}><b>Orange Sash</b></span> then going 
            on to achieve the coloured tags as they progress through the curriculum!
        </p>
        <p><i>Adults</i></p>
        <p>
            The Adult grading system follows a more familiar structure. The curriculum is delivered through a tiered
            system of coloured sash grades (identical to the belt system in other martial arts!). Adults begin their
            Kung Fu journey with no sash, and after around 3-6 Months are usually ready for their first grading to 
            obtain their <span style={{color: "Yellow"}}><b>Yellow Sash</b></span>. We encourage, but never force, 
            our students into progression. Students are welcome to hone their skills at any level until their 
            confidence and skills are what they deem prepared for progression.
        </p>
        <hr/>
        <hr/>

    </div>
)

export default Syllabus;