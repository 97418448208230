import React from "react";
import Venues from "../venues";

const Schedule = () => (
    <div className="Content">
        <hr/>
        <p><b>Bath Kung Fu Academy - Schedule</b></p>
        <hr/>
        <p>For prices and membership structure, please consult our <a href='/membership'>MEMBERSHIP</a> page.</p>
        <br/>
        <Venues />
    </div>
)

export default Schedule;