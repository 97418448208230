import React from "react";
import VenueCard from "./venue_card";

const Venues = () => (
    <>
    <p style={{fontSize: "1rem"}}>For further information:<br/>Tel: 0774 8901183<br/>info@kungfubath.com</p>
    <div style={{
        display: "flex",
        paddingRight: "3rem",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "stretch",
        padding: "1rem"
        }}>
        <VenueCard
            day="Monday"
            kidsTime="18:00 - 18:45"
            adultsTime="19:00 - 20:30"
            name="Corsham"
            address1="Springfield Community Campus"
            address2="Beechfield Road"
            city="Corsham"
            postcode="SN13 9DN"
            mapURL={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1243.7333953190869!2d-2.1941388337227283!3d51.43122080821864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48717bdd94be5ebf%3A0xd6c055aa3b839d99!2sSpringfield%20Community%20Campus!5e0!3m2!1sen!2suk!4v1719393510750!5m2!1sen!2suk"}/>
        <VenueCard
            day="Wednesday"
            kidsTime="18:00 - 18:45"
            name="Batheaston"
            address1="Batheaston Village Hall, School Lane"
            address2="Northend"
            city="Batheaston"
            postcode="BA1 7E"
            mapURL={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1759.801150986908!2d-2.3240694872721646!3d51.40793005810353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487183dd70e7086d%3A0xa340c5f4c67fc479!2sBatheaston%20Village%20Hall!5e0!3m2!1sen!2suk!4v1719394964308!5m2!1sen!2suk"}/>
        <VenueCard
            day="Wednesday"
            adultsTime="19:00 - 20:30"
            name="Batheaston"
            address1="Batheaston Scout Hall, School Lane"
            address2="Northend"
            city="Batheaston"
            postcode="BA1 7ER"
            mapURL={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d879.876072805766!2d-2.319908540798115!3d51.40920337365262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871832e0a8bbe79%3A0xaa68a5c9d82bfd58!2s23rd%20Bath%20(Avonvale)%20Scout%20Hut!5e0!3m2!1sen!2suk!4v1719393894066!5m2!1sen!2suk"}/>
        <VenueCard
            day="Thursday"
            kidsTime="18:00 - 18:45"
            adultsTime="19:00 - 20:30"
            name="Weston Village"
            address1="Weston Free Church"
            address2="High Street"
            city="Bath"
            postcode="BA1 4DB"
            mapURL={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2489.2851256783356!2d-2.3985261876637853!3d51.397815518585105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487186ad1101a793%3A0x696e7e8c90f5f35b!2sWeston%20Free%20Church!5e0!3m2!1sen!2suk!4v1719393961862!5m2!1sen!2suk"}/>
            
        {/* <VenueCard
            name="Trowbridge"
            address1="Staverton C.E. Primary"
            address2="School Lane, Staverton"
            city="Trowbridge"
            postcode="BA14 6NZ"/> */}
        {/* <VenueCard
            name="Colerne"
            address1="Colerne Village Hall"
            address2="Martins Croft"
            city="Colerne"
            postcode="SN14 8DT"
            mapURL={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1243.7333953190869!2d-2.1941388337227283!3d51.43122080821864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48717bdd94be5ebf%3A0xd6c055aa3b839d99!2sSpringfield%20Community%20Campus!5e0!3m2!1sen!2suk!4v1719393510750!5m2!1sen!2suk"}/>
             */}
        
    </div>
    </>
)

export default Venues;