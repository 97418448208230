import React from "react";

const textStyle = {
    fontSize: "1rem"
}
const textStyle2 = {
    fontSize: "0.75rem"
}

const VenueCard = ({
    day,
    kidsTime,
    adultsTime,
    name,
    address1,
    address2,
    city,
    postcode,
    mapURL
}) => {
    return (
    <div style={{ background: "#890606b8", borderRadius: "1rem", padding: "1rem", margin: "1rem", width: "100%"}}>
        <p style={textStyle}><b>{day}</b></p>
        {kidsTime ? (<p style={textStyle}>{"Juniors (5-11 years):"} <b>{kidsTime}</b></p>) : <br/>}
        {adultsTime ? (<p style={textStyle}>{"Adults (12 upwards):"} <b>{adultsTime}</b></p>) : <br/>}
        <hr/>
        <p style={textStyle}><b>{name}</b></p>
        <p style={textStyle2}>{address1}</p>
        {address2 && (<p style={textStyle2}>{address2}</p>)}
        {city && (<p style={textStyle2}>{city}</p>)}
        {postcode && (<p style={textStyle2}>{postcode}</p>)}
        {mapURL && (
            <iframe 
            title={`${name}-map`}
            src={mapURL}  
            style={{
                width:"100%",
                height:"15rem",
                border: 0
            }}
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        )}
    </div>
)}

export default VenueCard;