import React from "react";

const Footer = () => (
    <>
    <br/>
    <div style={{ 
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        }}>
            <p>Copyright © Bath Kung Fu Academy. All Rights Reserved <br/>
            Site Designed by Forest Tiger Technology</p>
    </div>
    </>
)

export default Footer;