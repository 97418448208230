import React from "react";
import grading_two from '../../images/Kung-Fu-Bath-Grading-2.jpg';

const AboutUs = () => (
    <div className="Content">
        <hr/>
        <p>Bath Kung Fu Academy - About Us</p>
        <hr/>
        <p><b>WHAT WE OFFER</b></p>
        <img src={grading_two} alt="kung fu bath academy grading" style={{ objectFit: "cover", width: "90%", height: "30vh", border: "4px ridge white" }} />
        <p>The Kung Fu Academy is pleased to offer professional step by step instruction that will guide you from being a complete beginner to an advanced practitioner.</p>
        <p>We pride ourselves on the Academy being fun, friendly & functional. The Academy is part of the Wei-t-o kung fu organization and is recognized by the British Counsel for Chinese martial arts (BCCMA).</p>
        <p>First Aid Qualified and CRB checked</p>
        <hr/>
        <p><b>ABOUT THE INSTRUCTOR</b></p>
        <p>Hi, my name is Jerry Burgess. I have been working in the fitness industry for over 20 years including being a former YMCA Health & Fitness Director. Coaching not only fitness but a variety of sports and activities to all ranges of age and ability, from children through to celebrities and world class athletes; <b>See testimonials below.</b></p>
        <p>My main area of expertise is Kung Fu with over 20 years of extensive experience. I am a former indoor student of Master Jeff Hasbrouck who was a fourth generation disciple of the legendary Master Tit Kiu Sam.</p>
        <p>I strongly believe in learning being safe and fun, whilst achieving the heights of skill that have made Kung Fu so legendary.</p>
        <hr/>
        <p><b>RECOMMENDATIONS & TESTIMONIALS</b></p>
        <p>"Although a different Art to my own, I fully respect the quality of Martial Arts that the Kung Fu Academy brings to the table both functionally and traditionally and after seeing it in action I'd definitely vouch for it."<br/>
            <b>Debbie Hill (4th Dan World Tae Kwon Do Champion)</b></p>
        <br/>
        <p>“This Academy is very impressive in its history, practicality & variety of empty hand & weapons systems.”<br/>
            <b>Richard Latham (Martial Arts, Guinness World Record holder)</b></p>
        <br/>
        <p>“There’s no doubt in my mind that the Kung Fu I’ve learnt from Sifu Burgess has massively helped my reaction time, balance, endurance, control and much more, leading to my most recent success as British Masters Champion.”<br/>
            <b>Mark Hucklebridge (British Motocross Champion)</b></p>
        <p>“I love it! I get to learn cool stuff so I’ve no worries about being bullied, plus the drills & games are big fun!!”<br/>
            <b>Thomas Flint (11 years old)</b></p>
        <p>“As a dance teacher I understand physical movement but studying at the Kung Fu Academy has enabled me to learn self defense skills that make me feel a lot safer and more confident.”<br/>
            <b>Samantha Ludwell (Dance teacher)</b></p>
        <p>See our great reviews on <a href="https://g.co/kgs/mTD8iv9">Google</a> too!</p>
        <hr/>
        <hr/>
    </div>
)

export default AboutUs;