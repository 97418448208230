import React from "react";
import ContactRow from "../contact_row";
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';

import china_one from '../../images/Kung-Fu-Bath-China-1.JPG';

const Contactus = () => (
    <div className="Content">
        <hr/>
        <p><b>Bath Kung Fu Academy - Contact Us</b></p>
        <hr/>
        <br/>
        <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "calc(1vw + 0.5rem)",
                color: 'white'
            }}>
                <ContactRow icon={<FacebookIcon/>} text={`${String.fromCharCode(160)}: Facebook`} linkTo={'https://www.facebook.com/BathKungFuAcademy'} whiteIcon/>
                <ContactRow icon={<MailIcon/>} text={`${String.fromCharCode(160)}: info@kungfubath.com`} linkTo={'mailto:info@kungfubath.com'} whiteIcon/>
                <ContactRow icon={<LanguageIcon/>} text={`${String.fromCharCode(160)}: http://www.kungfubath.com/`} linkTo={'http://www.kungfubath.com/'} whiteIcon/>
                <ContactRow icon={<PhoneAndroidIcon/>} text={`${String.fromCharCode(160)}: 0774 8901183`} linkTo={'tel:0774 8901183'} whiteIcon/>
            </div>
        <img src={china_one} alt="kung fu bath china trip metal statues" style={{ objectFit: "cover", width: "90%", height: "50vh", border: "4px ridge white" }} />
        <hr/>
        <hr/>
    </div>
)

export default Contactus;